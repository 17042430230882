.QuillTextEditor-label {
    margin-bottom: 2px;
    padding: 0 5px;
    color: #686972;
    margin-left: 14px;
    font-weight: 400;
    font-size: 12px;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.ql-container {
    min-height: 10em;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    border: 1px solid #ccc;
    border-top: 0 !important;;
}

.ql-container img {
    max-width: 100%;
    height: auto;
}

.ql-toolbar {
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

#toolbar {
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    border: 1px solid #ccc;
    padding: 6px;
}

#toolbar .sketch-picker {
    left: 290px;
    margin-top: -296px;
}

.ql-editor strong{
    font-family: sans-serif;
    font-weight:bold !important;
}

.ql-editor {
    font-family: sans-serif;
}

.ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before
{
    font-family: "Arial", cursive;
    content: "Arial";
}
.ql-picker.ql-font .ql-picker-label[data-value="Georgia"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Georgia"]::before
{
    font-family: "Georgia", cursive;
    content: "Georgia";
}
.ql-picker.ql-font .ql-picker-label[data-value="Helvetica"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Helvetica"]::before
{
    font-family: "Helvetica", cursive;
    content: "Helvetica";
}
.ql-picker.ql-font .ql-picker-label[data-value="Tahoma"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Tahoma"]::before
{
    font-family: "Tahoma", cursive;
    content: "Tahoma";
}

.ql-picker.ql-font .ql-picker-label[data-value="Times"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Times"]::before
{
    font-family: "Times", cursive;
    content: "Times";
}

.ql-picker.ql-font .ql-picker-label[data-value="Trebuchet"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Trebuchet"]::before
{
    font-family: "Trebuchet", cursive;
    content: "Trebuchet";
}

.ql-picker.ql-font .ql-picker-label[data-value="Verdana"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Verdana"]::before
{
    font-family: "Verdana", cursive;
    content: "Verdana";
}



.ql-font-Arial {
    font-family: "arial", sans-serif;
}

strong.ql-font-Arial {
    font-family: "arialbd", sans-serif;
}

.ql-font-Georgia {
    font-family: "georgia", serif;
}

strong.ql-font-Georgia {
    font-family: "georgiabd", serif;
}

.ql-font-Helvetica {
    font-family: "Helvetica", sans-serif;
}

strong.ql-font-Helvetica {
    font-family: "Helveticabd", sans-serif;
}

.ql-font-Tahoma {
    font-family: "tahoma", sans-serif;
}

strong.ql-font-Tahoma {
    font-family: "tahomabd", sans-serif;
}


.ql-font-Times {
    font-family: "times", sans-serif;
}

strong.ql-font-Times {
    font-family: "timesbd", sans-serif;
}

.ql-font-Trebuchet {
    font-family: "trebuc", sans-serif;
}

strong.ql-font-Trebuchet {
    font-family: "trebucbd", sans-serif;
}

.ql-font-Verdana {
    font-family: "verdana", sans-serif;
}

strong.ql-font-Verdana {
    font-family: "verdanabd", sans-serif;
}
